<template>
  <div style="height: 58px;"></div>
  <div class="cao-zuo-btn">
    <div class="dian-hua" @click="onClick(0)">
      <div><i :class="getBtnList[0].icon"></i></div>
      <div>{{ getBtnList[0].title }}</div>
    </div>
    <div class="ke-fu" @click="onClick(1)">
      <div><i :class="getBtnList[1].icon"></i></div>
      <div>{{ getBtnList[1].title }}</div>
    </div>
    <div class="can-yu" @click="onClick(2)">
      {{ getBtnList[2].title }}
    </div>
  </div>
</template>

<script>
import XEUtils from "xe-utils";
//底部购买按钮
export default {
  name: 'footerGouMaiBtn',
  mixins: [],
  props: {
    /**
     * [
     *  {icon:'',title:''}
     * ]
     */
    btnList: Array
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    onClick(index) {
      this.$emit('lol-click',this.btnList[index])
    }
  },
  watch: {},
  computed: {
    getBtnList() {
      if (!XEUtils.isArray(this.btnList)) return []
      let list = XEUtils.slice(this.btnList, 0, 3)
      list = list.map((n, index) => {
        console.log(index)
        return n
      })
      return list
    }
  }
}
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.cao-zuo-btn {
  display: flex;
  background-color: @white;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  font-size: 14px;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: @yin-ying-up;

  .dian-hua, .ke-fu {
    flex: 1.6;
    color: @blue;

    .tu-biao {
      font-size: 18px;
    }
  }

  .can-yu {
    flex: 3;
    height: 58px;
    line-height: 58px;
    background-color: @blue;
    color: @white;
    font-size: 18px;
  }
}
</style>
